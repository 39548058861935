<template>
  <v-card>
    <v-card-text class="pa-0">
      <template>
        <v-toolbar card color="white">
          <v-text-field
            flat
            solo
            prepend-icon="search"
            placeholder="Filtrar"
            v-model="search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-btn icon @click="cadastro">
            <v-icon>add_circle_outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="entradas"
          :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.fornecedor }}</td>
            <td>{{ props.item.dataCompraString }}</td>
            <td>{{ props.item.dataInclusaoString }}</td>
            <td>{{ formatMoney(props.item.valor) }}</td>
            <td class="text-xs-right">
              <v-btn @click="visualizar(props.item.id)" depressed icon dark color="primary" small>
                <v-icon small>remove_red_eye</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import { ServicoNotaEntrada } from "../../servicos/servicoNotaEntrada"
const servicoNotaEntrada = new ServicoNotaEntrada()
export default {
  data() {
    return {
      search: "",
      entradas: [],
      headers: [
        { text: "Código", align: "left", value: "id", sortable: false },
        { text: "Fornecedor", align: "left", value: "fornecedor", sortable: false },
        { text: "Data de compra", align: "left", value: "dataCompraString", sortable: false },
        { text: "Data de inclusão", align: "left", value: "dataInclusaoString", sortable: false },
        { text: "Valor", align: "right", value: "valor", sortable: false },
        { text: "", value: "action", align: "right", sortable: false }
      ]
    }
  },
  created() {
    servicoNotaEntrada.buscarNotasEntrada().then(
      res => {
        if (res.status === 200) {
          this.entradas = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    cadastro() {
      this.$router.push({ name: "add-entrada" })
    },
    visualizar(id) {
      this.$router.push({ name: "view-entrada", params: { id: id } })
    },
    formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
      try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? "-" : ""

        let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
        let j = i.length > 3 ? i.length % 3 : 0

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : "")
        )
      } catch (e) {
      // eslint-disable-next-line
        console.log(e)
      }
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
